import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, Row, Col } from 'antd';
import service from 'services/apiServices';

const layout = { labelCol: { span: 5 }, wrapperCol: { span: 19 } }

const TrancriptTagForm = ({ tags, onSubmit }) => {

  const [form] = Form.useForm();
  const [subs, setSubs] = useState([]);

  const onFinish = values => {
    onSubmit(values);
    form.setFieldsValue({ id: 0, tag: '', subtag: '' });
  }

  const tagSelected = async value => {
    const result = await service.list('master/otags/' + value);
    setSubs(result);
  }

  return (
    <Form form={form} {...layout} onFinish={onFinish}>
      <Form.Item label='Id' name='id' hidden>
        <Input />
      </Form.Item>
      <Row>
        <Col span={20}>
          <Form.Item label='Tag' name='tag' className="transFormField">
            <Select mode='combobox'
              showSearch optionFilterProp='children' onSelect={tagSelected}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {tags.map(c => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
            </Select>
          </Form.Item>
          <Form.Item label='Subtag' name='subtag' className="transFormField">
            <Select mode='combobox'
              showSearch optionFilterProp='children'
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {subs.map(c => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button type='primary' htmlType='submit'>ADD</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default TrancriptTagForm;